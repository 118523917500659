import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import products_fr from '../../data/products_fr';
import products_en from '../../data/products_en';
import Button from '../../components/Buttons/Button'; 
import QuantitySelector from '../../components/Buttons/QuantitySelector'; 
import { FaTruck, FaLock, FaEnvelope } from 'react-icons/fa'; // Import the icons from react-icons
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'; // Import the chevron icons
import DropdownButton from '../../components/Buttons/DropdownButton'; // Import the new DropdownSection component
import RightSidebar from '../../components/Side/SideBarPanier';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/cartSlice';
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';

const ProductDetail = () => {
  const { t, i18n } = useTranslation();

  const { id } = useParams();
  const products = i18n.language === 'fr' ? products_fr : products_en;

  const product = products.find((product) => product.id === id);

  const dispatch = useDispatch()

  const [expandedSections, setExpandedSections] = useState({
    delivery: false,
    payment: false,
    contact: false,
  });

  const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const toggleSection = (section) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const toggleCartSidebar = () => {
    setIsCartSidebarOpen(!isCartSidebarOpen);
  };

  const addToCartt = () => {
    console.log("Quantité ajoutée :", selectedQuantity); // Vérifie si la quantité est correcte

    dispatch(addToCart({
      id:product.id, title:product.title, image:product.imageLg, price:product.price, quantity: selectedQuantity
    }))
    toggleCartSidebar();
  };


  if (!product) {
    return <div>Product not found</div>;
  }

  const stockWidth = `${product.stock}%`;
  const stockColor = product.stock > 50 ? 'bg-green-600' : 'bg-red-600';

  return (
    <>
    <Helmet>
        <title>{`${product.title} | Au Coing du Ventoux`}</title>
        <meta name="description" content={`Achetez ${product.title}, un produit unique. Livraison rapide.`} />
        <meta property="og:title" content={product.title} />
        <meta property="og:description" content={`Achetez ${product.title}, un produit unique. Livraison rapide.`} />
        <meta property="og:image" content={product.imageLg} />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={`https://aucoingduventoux.com/product/${id}`} />
        <link rel="canonical" href={`https://aucoingduventoux.com/product/${id}`} />
      </Helmet>

      <div className="mx-auto px-20 py-20 pt-40 md:pt-64 md:px-20 xs:px-5 2xs:px-5">
      <div className="flex flex-col md:flex-row items-start">
        <div className="w-full md:w-1/2 flex justify-center items-center mb-4 md:mb-0">
          <img className="max-h-[300px] w-auto object-contain md:max-h-[700px]" src={product.imageLg} alt={product.title} />
        </div>
        <div className="w-full md:w-1/2 md:ml-8">
          <h1 className="text-xl md:text-3xl font-bold md:text-left">{product.title}</h1>
          <p className="text-base md:text-xl text-gray-700 mt-2 md:text-left">
            {t('product.details.price')}: {product.price.toFixed(2)} €
          </p>
          <hr className="my-4 border-gray-300" />
          <div className="mt-4 md:text-left">
            <p className="font-bold">{t('product.details.stock')} - {product.quantitytitle}</p>
            <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2 overflow-hidden">
              <div className={`h-2.5 rounded-full ${stockColor}`} style={{ width: stockWidth }}></div>
            </div>
          </div>
          <div className="mt-4 md:text-left">
            <p className="font-bold">{t('product.details.quantity')}</p>
            <div className="flex md:justify-start mt-2">
              <QuantitySelector onQuantityChange={setSelectedQuantity} initialQuantity={1}/>
            </div>
          </div>
          <div className="flex justify-center md:justify-start mt-4 w-full">
            <Button text={t('product.details.add_to_cart')} onClick={addToCartt} variant="black" className="w-full" />
          </div>
          <div className="mt-8">
            <h2 className="text-2xl font-bold">{t('product.details.description')}</h2>
            <div>
              {product.description.map((line, index) => (
                <p key={index} className="mt-2 text-gray-700 text-base font-normal">
                  {line}
                </p>
              ))}
            </div>
          </div>

          <div className="mt-8 space-y-4">
            <DropdownButton
              icon={FaTruck}
              title={t('product.details.delivery.title')}
              content={t('product.details.delivery.content')}
              isOpen={expandedSections.delivery}
              onClick={() => toggleSection('delivery')}
            />
            <DropdownButton
              icon={FaEnvelope}
              title={t('product.details.contact.title')}
              content={t('product.details.contact.content')}
              isOpen={expandedSections.contact}
              onClick={() => toggleSection('contact')}
            />
          </div>
        </div>
      </div>
      <RightSidebar isOpen={isCartSidebarOpen} toggleSidebar={toggleCartSidebar} />
    </div>
    </>
  );
};

export default ProductDetail;
