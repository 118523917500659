import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t, i18n } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // ⚡ FAQQs en Français ⚡
  const faqsFr = [
    {
      question: "Est-ce que vos produits sont artisanaux et locaux ?",
      answer: `
        Nous sommes transformateurs et achetons nos coings chez des producteurs de proximité dans le Vaucluse et en Drôme provençale, qui cultivent des coings de qualités.
        Cependant nous disposons de cognassiers qui nous permettent de fabriquer une partie de nos produits sucrés.
      `,
      author: "Répondu par Guimety Corinne",
    },
    {
      question: "Disposez-vous d'un point de vente sur place ?",
      answer: `
        Non, nos produits sont distribués dans des épiceries fines, des caveaux, des restaurants et des supermarchés.
        Vous retrouverez la liste complète dans la page "Points de Vente" du site.
      `,
      author: "Répondu par l'équipe Au Coing Du Ventoux",
    },
    {
      question: "Proposez-vous la vente en ligne ?",
      answer: `
        Pour l'instant, l'utilisation de notre site est limitée à la présentation de nos produits et de notre entreprise.
        Un formulaire de commande est disponible après avoir rempli votre panier et validé votre email.
        Dans un futur proche, nous envisageons de mettre en place un système de vente en ligne.
      `,
      author: "Répondu par l'équipe de développement",
    },
    {
      question: "Quelle est la durée de conservation de vos produits ?",
      answer: `
        Nos produits sont des produits artisanaux, sans conservateurs, ni colorants. Certains produits sont à conserver au frais après ouverture.
        Au dos de chaque étiquette, vous trouverez la date de fabrication et la date de péremption.
      `,
      author: "Répondu par Guimety Corinne",
    },
  ];

  // 🌎 FAQQs en Anglais 🌎
  const faqsEn = [
    {
      question: "Are your products artisanal and local?",
      answer: `
        We are processors and buy our quinces from local producers in Vaucluse and Drôme Provençale, who cultivate high-quality quinces.
        However, we also have our own quince trees, allowing us to produce some of our sweet products.
      `,
      author: "Answered by Guimety Corinne",
    },
    {
      question: "Do you have a physical store?",
      answer: `
        No, our products are available in fine grocery stores, wine cellars, restaurants, and supermarkets.
        You can find the complete list on the "Sales Points" page of our website.
      `,
      author: "Answered by the Au Coing Du Ventoux team",
    },
    {
      question: "Do you offer online sales?",
      answer: `
        For now, our website is mainly for presenting our products and company.
        An order form is available once you fill your cart and validate your email.
        In the near future, we plan to introduce an online sales system.
      `,
      author: "Answered by the development team",
    },
    {
      question: "What is the shelf life of your products?",
      answer: `
        Our products are artisanal, with no preservatives or colorants. Some products must be kept refrigerated after opening.
        You can find the manufacturing and expiration dates on the back of each label.
      `,
      author: "Answered by Guimety Corinne",
    },
  ];

  // 📌 Sélectionne la FAQ en fonction de la langue active 📌
  const faqs = i18n.language === "fr" ? faqsFr : faqsEn;

  return (
    <div className="mx-auto py-16 md:px-36 px-10">
      <h2 className="text-3xl font-bold mb-6">{t("faq.title")}</h2>

      <div>
        {faqs.map((faq, index) => (
          <div key={index} className="border-b">
            <button
              onClick={() => toggleFAQ(index)}
              className="w-full text-left py-4 flex justify-between items-center"
            >
              <span className="text-xl font-semibold">{faq.question}</span>
              <span className="text-gray-500">{openIndex === index ? "▲" : "▼"}</span>
            </button>
            {openIndex === index && (
              <div className="bg-gray-100 p-4">
                <p className="text-gray-700 mb-2">{faq.answer}</p>
                <p className="text-sm text-gray-500">{faq.author}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
