import petillantImage from '../assets/img/petillant-sf.png';
import coudounatImage from '../assets/img/coudounat-sf.webp';
import petillantImageGros from '../assets/img/petillant-sfnon.png';
import coudounatImageGros from '../assets/img/coudounat-sfnon.png';
import siropImg from "../assets/img/LeSirop.png";
import laPateImg from "../assets/img/LaPate.png";
import laGeleeImg from "../assets/img/LaGelee.png";
import laGeleeCoudounatImg from "../assets/img/LaGeleeCoudounat.png";

const products_en = [
    {
        id: 'petillant',
        imageSm: petillantImage,
        imageLg: petillantImageGros,
        title: "The Sparkling Quince (10°) - 75cl",
        description: [
            "The base of this drink is Quince Aperitif, which is carbonated.",
            "It is a fresh and light beverage, best enjoyed as an aperitif or with dessert."
        ],
        price: 12.00,
        stock: 1500,
        quantitytitle: "In Stock"
    },
    {
        id: 'coudounat',
        imageSm: coudounatImage,
        imageLg: coudounatImageGros,
        title: "Coudounat (16°) - 70cl",
        description: [
            "Quinces are crushed and cold-pressed to extract juice, which is then fortified with alcohol and a small amount of sugar.",
            "Coudounat is aged in vats to develop its unique aromas.",
            "Before bottling, it is filtered, giving it its characteristic golden color.",
            "Best served chilled (between 8° and 10°) as an aperitif or digestif.",
            "Ideal for deglazing foie gras or pairing with it.",
            "Pairs beautifully with strong cheeses (goat cheese, Roquefort), desserts (chocolate fondant...),",
            "melon, and as a Provençal-style palate cleanser with a scoop of vanilla ice cream."
        ],
        price: 17.00,
        stock: 1500,
        quantitytitle: "In Stock"
    },
    {
        id: 'Le Sirop du Coing',
        imageSm: siropImg,
        imageLg: siropImg,
        title: "Quince Syrup - 25cl",
        description: [
            "This syrup is made using hot extraction.",
            "Enjoy with still or sparkling water. It can also be used as a base for a kir or cocktail."
        ],
        price: 6.00,
        stock: 20,
        quantitytitle: "Limited Stock"
    },
    {
        id: 'Le Pate du Coing à tartiner',
        imageSm: laPateImg,
        imageLg: laPateImg,
        title: "Quince Spread - 340g",
        description: [
            "This fruit paste is made from quince pulp with minimal added sugar.",
            "Enjoy on toast or paired with cheese."
        ],
        price: 7.00,
        stock: 20,
        quantitytitle: "Limited Stock"
    },
    {
        id: 'Le Gelée Du Coing',
        imageSm: laGeleeImg,
        imageLg: laGeleeImg,
        title: "Quince Jelly - 350g",
        description: [
            "This jelly is made from the cooking juice of quinces with minimal added sugar.",
            "Perfect for breakfast or as a snack on toast, in yogurt, or as a topping for tarts."
        ],
        price: 6.00,
        stock: 50,
        quantitytitle: "Limited Stock"
    },
    {
        id: 'Le Gelée Du Coudounat',
        imageSm: laGeleeCoudounatImg,
        imageLg: laGeleeCoudounatImg,
        title: "Coudounat Jelly - 100g",
        description: [
            "A Coudounat jelly made from Quince Aperitif.",
            "Best enjoyed with strong cheeses or foie gras."
        ],
        price: 3.00,
        stock: 50,
        quantitytitle: "Limited Stock"
    },
];

export default products_en;
