import React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa"; // Import des icônes

const SocialLinks = () => {
  return (
    <div className="flex justify-center space-x-8 py-10 bg-white">
      {/* Lien Facebook */}
      <a
        href="https://www.facebook.com/profile.php?id=100063601626814"
        target="_blank"
        rel="noopener noreferrer"
        className="group flex items-center justify-center w-20 h-20 border-2 border-black rounded-full transition-transform transform hover:scale-110 hover:bg-white"
      >
        <FaFacebookF className="text-black group-hover:text-gray-900 text-3xl" />
      </a>

      {/* Lien Instagram */}
      <a
        href="https://www.instagram.com/aucoingduventoux/"
        target="_blank"
        rel="noopener noreferrer"
        className="group flex items-center justify-center w-20 h-20 border-2 border-black rounded-full transition-transform transform hover:scale-110 hover:bg-white"
      >
        <FaInstagram className="text-black group-hover:text-gray-900 text-3xl" />
      </a>
    </div>
  );
};

export default SocialLinks;
