import petillantImage from '../assets/img/petillant-sf.png';
import coudounatImage from '../assets/img/coudounat-sf.webp';
import petillantImageGros from '../assets/img/petillant-sfnon.png';
import coudounatImageGros from '../assets/img/coudounat-sfnon.png'
import siropImg from "../assets/img/LeSirop.png";
import laPateImg from "../assets/img/LaPate.png";
import laGeleeImg from "../assets/img/LaGelee.png";
import laGeleeCoudounatImg from "../assets/img/LaGeleeCoudounat.png";

const products_fr = [
    {
      id: 'petillant',
      imageSm: petillantImage,
      imageLg: petillantImageGros,
      title: "Le Coing Qui Petille (10°) - 75cl",
      description: [
        "La base de cette boisson est l'Apéritif du Coing, qui est mis en bulles par gazéification.",
        "C'est une boisson fraîche et légère qui se sert en apéritif ou avec un dessert."
      ],
      price: 12.00,
      stock: 1500,
      quantitytitle: "In Stock"
    },
    {
      id: 'coudounat',
      imageSm: coudounatImage,
      imageLg: coudounatImageGros,
      title: "Coudounat (16°) - 70cl",
      description: [
        "Les coings sont broyés et pressés à froid pour obtenir un jus qui sera muté à l'alcool avec ajout de peu de sucre.",
        "Le Coudounat est mis en cuve et vieillit lentement pour obtenir un breuvage aux arômes particuliers.",
        "Il sera filtré avant embouteillage ce qui lui donne sa teinte couleur or.",
        "Il se sert frais ( entre 8° et 10° ) à l'apéritif comme en digestif.",
        "Idéal pour déglacer un foie gras ou l'accompagner.",
        "Il se marie également à merveille avec les fromages typés ( fromages de chèvre , roquefort), les desserts ( moelleux aux chocolat .. )",
        "le melon et en trou provençal ( boule de glace à la vanille)."
      ],
    
      price: 17.00,
      stock: 1500,
      quantitytitle: "In Stock"
    },
    {
      id: 'Le Sirop du Coing',
      imageSm: siropImg,
      imageLg: siropImg,
      title: "Le Sirop du Coing - 25cl",
      description: [
        "Ce sirop est produit par extraction à chaud.",
        "A consommer avec de l’eau plate ou gazeuse. Peut aussi servir de base pour un kir ou un cocktail",
      ],
      price: 6.00,
      stock: 20,
      quantitytitle: "Limited Stock"
    },
    {
      id: 'Le Pate du Coing à tartiner',
      imageSm: laPateImg,
      imageLg: laPateImg,
      title: "La Pate du Coing à tartiner - 340g",
      description: [
        "Cette pâte de fruit est faite à partir de la pulpe du coing avec peu de sucre ajouté.",
        "A déguster sur une tartine, avec du fromage.",
      ],
      price: 7.00,
      stock: 20,
      quantitytitle: "Limited Stock"
    },
    {
      id: 'Le Gelée Du Coing',
      imageSm: laGeleeImg,
      imageLg: laGeleeImg,
      title: "La Gelée Du Coing - 350g",
      description: [
        "Cette gelée est faite à partir du jus de cuisson des coings avec ajout de peu de sucre.",
        "Elle peut se prendre au petit déjeuner ou au gouter sur une tartine, dans un laitage ou servir de nappage sur une tarte.",
      ],
      price: 6.00,
      stock: 50,
      quantitytitle: "Limited Stock"
    },
    {
      id: 'Le Gelée Du Coudounat',
      imageSm: laGeleeCoudounatImg,
      imageLg: laGeleeCoudounatImg,
      title: "La Gelée Du Coudounat - 100g",
      description: [
        "Une gelée de coudounat faite à partir de l&#39;Apéritif du Coing.",
        "En accompagnement d’un fromage typé, du foie gras.",
      ],
      price: 3.00,
      stock: 50,
      quantitytitle: "Limited Stock"
    },
  ];
  
  export default products_fr;
  