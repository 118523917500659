import React from 'react';
import Slider from 'react-slick';
import ProductCard from './ProductCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import products_fr from '../../data/products_fr';
import products_en from '../../data/products_en';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useTranslation } from 'react-i18next';


// Fonction utilitaire pour les styles des flèches
// Fonction utilitaire pour les styles des flèches minimalistes
const arrowStyle = (position) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "#fff",
  color: "#333",
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  zIndex: 10,
  transition: "all 0.3s ease-in-out",
  [position]: "10px", // Position dynamique (right ou left)
});

// Flèche suivante avec effets hover & active
function SampleNextArrow({ onClick }) {
  return (
    <div
      className="custom-arrow next-arrow"
      onClick={onClick}
      style={{
        ...arrowStyle("right"),
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = "#f0f0f0"; // Fond gris clair au hover
        e.currentTarget.style.transform = "translateY(-50%) scale(1.1)"; // Agrandissement au hover
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = "#fff";
        e.currentTarget.style.transform = "translateY(-50%) scale(1)"; // Retour à la normale
      }}
      onMouseDown={(e) => {
        e.currentTarget.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)"; // Ombre réduite au clic
      }}
      onMouseUp={(e) => {
        e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)"; // Retour à la normale après le clic
      }}
    >
      <FaArrowRight className="text-xl" />
    </div>
  );
}

// Flèche précédente avec effets hover & active
function SamplePrevArrow({ onClick }) {
  return (
    <div
      className="custom-arrow prev-arrow"
      onClick={onClick}
      style={{
        ...arrowStyle("left"),
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = "#f0f0f0";
        e.currentTarget.style.transform = "translateY(-50%) scale(1.1)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = "#fff";
        e.currentTarget.style.transform = "translateY(-50%) scale(1)";
      }}
      onMouseDown={(e) => {
        e.currentTarget.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
      }}
      onMouseUp={(e) => {
        e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
      }}
    >
      <FaArrowLeft className="text-xl" />
    </div>
  );
}

const Products = () => {
  const { t, i18n } = useTranslation();

    const products = i18n.language === 'fr' ? products_fr : products_en;

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <div className="w-full">
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product.id} className="p-4">
            <ProductCard
              id={product.id}
              image={product.imageSm}
              title={product.title}
              description={product.description}
              price={product.price}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Products;
