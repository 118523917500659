import emailjs from 'emailjs-com';

const handleSendEmail = async (formData) => {
  try {
    // Envoi des données via EmailJS
    const result = await emailjs.send(
      "service_8cfov47", // Remplacez par votre Service ID
      "template_6yexdjm", // Remplacez par votre Template ID
      {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
      },
      "NkXzFJGQ3E8SLK7K8" // Remplacez par votre clé publique
    );

    console.log("Email envoyé avec succès:", result);
  } catch (error) {
    console.error("Erreur:", error);
    alert("Une erreur s'est produite lors de l'envoi de l'email.");
  }
};

export default handleSendEmail;
