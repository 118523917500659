import React, { useEffect, useRef, useState, useContext } from "react";
import { EventsContext } from "../services/EventsContext";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet-async";

import {
    GoogleMap,
    Marker,
    InfoWindow,
    useLoadScript,
  } from "@react-google-maps/api";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import SVGSeparator from '../services/SVGSeparator';
import background from "../assets/img/beaufond.jpg";

const mapOptions = {
    mapTypeControl: false, // Désactiver le choix du type de carte (plan/satellite)
    streetViewControl: false, // Désactiver le bonhomme pour Street View
}

const containerStyle = {
    width: "100%",
    height: "100%",
  };
  
  const defaultCenter = {
    lat: 46.603354,
    lng: 1.888334, // Centre sur la France
  };
  // Définition des icônes par type de commerce
  const categoryIcons = {
    supermarche: "https://cdn-icons-png.flaticon.com/128/2331/2331970.png",
    restaurant: "https://cdn-icons-png.flaticon.com/128/1046/1046784.png",
    cave: "https://cdn-icons-png.flaticon.com/128/2969/2969202.png",
    default: "https://cdn-icons-png.flaticon.com/128/684/684908.png",
  };


  const markerSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 384 512">
  <path fill="red" d="M172.268 501.67C51.137 300.305 0 248.89 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 56.89-51.137 108.305-172.268 309.67a24 24 0 01-39.464 0zM192 272a80 80 0 1080-80 80.091 80.091 0 00-80 80z"/>
</svg>
`;

// Convertir le SVG en Data URI
const markerIconUrl = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(markerSvg)}`;


const SalesPointsMap = () => {
    const { salesPoints } = useContext(EventsContext);

    const { t, i18n } = useTranslation();
    const mapRef = useRef(null);

    const [search, setSearch] = useState("");
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [geocodedPoints, setGeocodedPoints] = useState([]); // Stockage des points géocodés

    // Charge la carte via Google Maps API
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: "AIzaSyCZKd9GsscBfVyRmXv1azAVbTlh0qPnfGc", // Remplacez par votre clé API
    });
  
    useEffect(() => {
      if (isLoaded && salesPoints.length > 0) {
        const geocoder = new window.google.maps.Geocoder();
  
        // Géocoder chaque adresse pour récupérer les lat/lng
        Promise.all(
          salesPoints.map((point) => {
            return new Promise((resolve) => {
              geocoder.geocode({ address: point.address }, (results, status) => {
                if (status === "OK" && results[0]) {
                  const { lat, lng } = results[0].geometry.location;
                  resolve({
                    ...point,
                    lat: lat(),
                    lng: lng(),
                    category: point.category || "default",
                  });
                } else {
                  console.error(`Erreur de géocodage pour ${point.address} : ${status}`);
                  resolve(point); // Retourner le point sans modification en cas d'échec
                }
              });
            });
          })
        ).then((points) => {
          setGeocodedPoints(points);
        });
      }
    }, [isLoaded, salesPoints]);
  
    if (!isLoaded) return <div>Chargement...</div>;

     // Filtrer les points selon la recherche
  const filteredPoints = geocodedPoints.filter(
    (point) =>
      point.name.toLowerCase().includes(search.toLowerCase()) ||
      point.city.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
    <Helmet>
        <title>Nos Points de Vente | Au Coing du Ventoux</title>
        <meta name="description" content="Trouvez un point de vente près de chez vous pour acheter notre alcool de coing artisanal." />
        <meta property="og:title" content="Nos Points de Vente | Au Coing du Ventoux" />
        <meta property="og:url" content="https://aucoingduventoux.com/sales" />
        <link rel="canonical" href="https://aucoingduventoux.com/sales" />
      </Helmet>
    {/* Section Héro */}
    <div className="relative bg-cover bg-center h-screen" style={{ backgroundImage: `url(${background})`}}>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-6">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">{t('sales.bigtitle')}</h1>
        {/* Séparateur SVG */}
            <SVGSeparator width={120} height={50} className="" />
        <p className="mt-4 text-lg md:text-xl max-w-3xl">
            {t('sales.bigdescription')}
        </p>
    </div>

    </div>
    <h2 className="text-center  px-6 text-3xl md:text-4xl font-bold text-gray-800 mt-10">
        {t('sales.title')}
    </h2>
    <p className="text-center px-6 py-5"> {t('sales.description')}</p>
    <SVGSeparator width={120} height={50} className="pb-2" />


    <div className="flex flex-col md:flex-row h-screen">
  {/* Liste et recherche */}
  <div className="w-full md:w-1/3 p-8 max-h-[50vh] md:max-h-full mb-10 md:mb-10">
    <input
      type="text"
      placeholder="Rechercher un point de vente..."
      className="border p-2 w-full mb-4"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
    />
    <ul className="space-y-4 max-h-[350px] md:max-h-[400px] overflow-auto border rounded p-4">
      {filteredPoints.map((point) => (
        <li
          key={point.id}
          className="p-4 border rounded shadow cursor-pointer hover:bg-gray-100"
          onClick={() => setSelectedPoint(point)}
        >
          <h3 className="font-bold">{point.name}</h3>
          <p>{point.address}</p>
          <p>{point.city}</p>
        </li>
      ))}
    </ul>
  </div>

  {/* Carte interactive */}
  <div className="w-full md:w-2/3 flex-grow h-[50vh] md:h-full p-8">
  <GoogleMap
    mapContainerStyle={{ width: "100%", height: "100%" }}
    center={defaultCenter}
    zoom={6}
    options={mapOptions}
    >
    {filteredPoints.map((point) => (
        <Marker
        key={point.id}
        position={{ lat: point.lat, lng: point.lng }}
        onClick={() => setSelectedPoint(point)}
        icon={{
            url: categoryIcons[point.category] || categoryIcons.default,
            scaledSize: new window.google.maps.Size(40, 40), // Taille de l'icône
        }}
        animation={window.google.maps.Animation.DROP} // Animation au chargement
        />
    ))}

    {selectedPoint && (
        <InfoWindow
        position={{ lat: selectedPoint.lat, lng: selectedPoint.lng }}
        onCloseClick={() => setSelectedPoint(null)}
        >
        <div>
            <h3 className="font-bold">{selectedPoint.name}</h3>
            <p>{selectedPoint.address}</p>
            <p>{selectedPoint.city}</p>
        </div>
        </InfoWindow>
    )}
    </GoogleMap>
  </div>
</div>

    </>
  );
};

export default SalesPointsMap;

