import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//IGAAMzNHxsnqxBZAE9zcEJjZAVUzTzE1UUNxNHpIcU1LdEpoSTJsMGhFRGNvTlVGYU5lSHg5d2JxSjEtYW9SWXJfWXZABR3Vmc2h0S2I4UmF0LTNxLU1lc19kelU2UDd2NEk2V1EzUXJRUl9tM0ZAKaUdLam5ORmpVTDJVWWNjdF9iTQZDZD
const InstagramFeed = () => {
  const [posts, setPosts] = useState([]);
    const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchInstagramPosts = async () => {
      try {
        const response = await fetch(
            

          "https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=IGAAMzNHxsnqxBZAE9zcEJjZAVUzTzE1UUNxNHpIcU1LdEpoSTJsMGhFRGNvTlVGYU5lSHg5d2JxSjEtYW9SWXJfWXZABR3Vmc2h0S2I4UmF0LTNxLU1lc19kelU2UDd2NEk2V1EzUXJRUl9tM0ZAKaUdLam5ORmpVTDJVWWNjdF9iTQZDZD"
        );
        const data = await response.json();
        setPosts(data.data);
      } catch (error) {
        console.error("Erreur de chargement des posts Instagram :", error);
      }
    };

    fetchInstagramPosts();
  }, []);

  return (
    <div className="text-center py-8">
      <h2 className="text-2xl font-semibold">{t('insta.aventure')}</h2>
      <p className="text-lg italic text-gray-500 mb-10">{t('insta.partage')}</p>

      <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-8 p-4">
        {posts.map((post) => (
          <a
            key={post.id}
            href={post.permalink}
            target="_blank"
            rel="noopener noreferrer"
            className="relative group overflow-hidden rounded-sm"
          >
            {/* Gestion des images */}
            {post.media_type === "IMAGE" && (
              <img
                src={post.media_url}
                alt={post.caption || "Instagram post"}
                className="w-full h-full object-cover rounded-sm transition-transform transform group-hover:scale-105"
              />
            )}

            {/* Gestion des vidéos et Reels */}
            {post.media_type === "VIDEO" && (
              <div className="relative">
                <img
                  src={post.thumbnail_url || post.media_url} // Fallback sur media_url si thumbnail_url est absent
                  alt="Reel Instagram"
                  className="w-full h-full object-cover rounded-sm transition-transform transform group-hover:scale-105"
                />
                {/* Overlay avec icône de lecture */}
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 group-hover:bg-opacity-50 transition-opacity">
                  <span className="text-white text-3xl">▶</span>
                </div>
              </div>
            )}
          </a>
        ))}
      </div>
    </div>
  );
};

export default InstagramFeed;
