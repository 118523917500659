import React, { useState, useEffect, useMemo } from 'react';
import { useTransition, animated } from 'react-spring';
import backgroundImage from '../assets/img/coudounat.jpeg';
import backgroundImage2 from '../assets/img/petillant.jpeg';
import Button from '../components/Buttons/Button'; // Assurez-vous que le chemin d'importation est correct
import { useTranslation, Trans } from 'react-i18next';
import ProductCard from '../components/Cards/ProductCard';
import Product from '../components/Cards/Products';
import FAQ from '../components/FAQ/FAQ';
import { useNavigate } from 'react-router-dom'; // Import de useNavigate
import SVGSeparator from '../services/SVGSeparator';
import AgeVerificationModal from './AgeVerificationModal';
import InstagramFeed from '../services/InstagramFeed';
import SocialLinks from '../services/SocialLinks';
import { Helmet } from "react-helmet-async";

export const Home = () => {
    const navigate = useNavigate(); // Initialisation de useNavigate

    const { t, i18n } = useTranslation();

    const slides = [
        { id: 1, url: backgroundImage },
        { id: 2, url: backgroundImage2 },
    ];


    const [index, setIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);  // État pour stocker la largeur de la fenêtre

   

    const transitions = useTransition(index, {
        from: { opacity: 0, transform: 'scale(1.1)' }, // Commence légèrement agrandi et invisible
        enter: { opacity: 1, transform: 'scale(1)' }, // Réduit progressivement avec l’opacité
        leave: { opacity: 0, transform: 'scale(1)' }, // Reste à taille normale en disparaissant
        config: { tension: 280, friction: 30, duration: 1200 }, // Ajustement du timing
        keys: index
    });
    

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(current => (current + 1) % slides.length);
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const goToSlide = (slideIndex) => {
        setIndex(slideIndex);
    };

    /** 
    const getBackgroundSize = useMemo(() => {
        console.log(windowWidth)
      if (windowWidth < 768) { // pour les tablettes et plus petits
        return 'cover'; // zoom plus grand pour éviter trop de rétrécissement
      } else {
        return '110%'; // zoom standard pour les grands écrans
      }
    }, [windowWidth]);
    */
    
    const getBackgroundPosition = (currentUrl) => {
      // Vérifiez si l'URL de l'image courante contient le mot "coudounat"
      if (currentUrl.includes('coudounat')) {
        return '90% 25%'; // Position pour coudounat
      } else {
        return '5% 18%'; // Position par défaut
      }
    };

    return (
        <>
        <Helmet>
            <title>Au Coing du Ventoux | Des produits à base de Coing</title>
            <meta name="description" content="Découvrez nos alcool de coing artisanaux ainsi que leurs produits dérivés, fabriqué au pied du Mont Ventoux. Livraison rapide et directe du producteur." />
            <meta name="keywords" content="alcool de coing, liqueur de coing, eau de vie, boutique artisanal, Mont Ventoux" />
            <meta property="og:title" content="Au Coing du Ventoux | Des produits à base de Coing" />
            <meta property="og:description" content="Découvrez nos alcool de coing artisanaux ainsi que leurs produits dérivés, fabriqué au pied du Mont Ventoux. Livraison rapide et directe du producteur." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://aucoingduventoux.com/" />
            <meta property="og:image" content="https://aucoingduventoux.com/images/bouteille-coing.jpg" />
            <link rel="canonical" href="https://aucoingduventoux.com/" />
        </Helmet>
        
        <div>
            <div className='max-w-full h-screen relative bg-cover overflow-hidden '>
            
                {/* Contenu de votre page Home */}
                
                {transitions((styles, i) => (
                <>
                    <animated.div
                    style={{
                        ...styles,
                        backgroundImage: `url(${slides[i].url})`,
                        width: '100%',
                        minHeight: '100vh', // Remplace height: 100% par minHeight: 100vh
                        backgroundPosition: getBackgroundPosition(slides[i].url),
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundAttachment: window.innerWidth > 768 ? 'fixed' : 'scroll', // Fixé sur desktop, scroll sur mobile
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: -1, // Assurez-vous que ça reste en arrière-plan
                        willChange: 'opacity, transform'
                    }}
                    />
                    {/* Overlay pour assombrir l'image */}
                    <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    }}
                    ></div>
                </>
                ))}

            </div>
            
                <div className="absolute top-28 md:top-60 xl:top-80 left-1/2 transform -translate-x-1/2 w-4/5 text-white">
                    <p className=' font-sans text-center text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-extrabold'>{t('home.title')}</p> {/* Texte ajustable */}
                    <p className='relative font-nunito text-center top-6 text-lg sm:text-xl md:text-1xl lg:text-xl xl:text-2xl'>{t('home.description')} </p> {/* Texte ajustable */}

                    <div className='absolute hover:bg-gray-100 text-gray-800 top-60 sm:top-56  md:top-40 xl:top-48 left-1/2 transform -translate-x-1/2'>
                        <Button
                            text={t('home.button.discover')}
                            onClick={() => navigate('/aboutus')} // Redirection avec useNavigate
                            variant="ok"
                        />
                    </div>
                </div>

                <div className='absolute bottom-0 left-0 right-0 flex justify-center mb-8'>
                    {slides.map((slide, slideIndex) => (
                        <div
                            key={slide.id}
                            onClick={() => goToSlide(slideIndex)}
                            className={`w-4 h-4 mx-2 rounded-full cursor-pointer ${index === slideIndex ? 'bg-gray-900' : 'bg-white'}`}
                        ></div>
                    ))}
                </div>


                <div className="pt-30 xl:pt-40 xl:pr-20 xl:pl-20 md:pt-40 md:pr-20 md:pl-20 sm:pt-30 sm:pr-5 sm:pl-5 xs:pt-20 xs:pr-5 xs:pl-5 2xs:pt-20 2xs:pr-5 2xs:pl-5 relative overflow-hidden">
                {/* Ajout des motifs de coings */}
                <div>
                    <img
                    src="/assets/img/quince.png"
                    alt="Coing"
                    className="absolute top-0 -left-10 w-24 opacity-5"
                    />
                    <img
                    src="/assets/img/quince.png"
                    alt="Coing"
                    className="absolute top-32 -right-10 w-32 opacity-5"
                    />
                   
                </div>
                <p className='text-center font-nunito text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold'> 
                    <Trans i18nKey="home.section1.title"></Trans>
                </p>
                <p className='pt-10 text-center font-nunito text-xl xl:pl-40 xl:pr-40'>
                    {t('home.section1.description')}
                </p>
                <p className='pt-10 text-center font-nunito text-xl xl:pl-40 xl:pr-40'>
                    {t('home.section1.description2')}
                </p>
                <p className='pt-10 text-center font-nunito text-xl xl:pl-40 xl:pr-40'>
                    {t('home.section1.description3')}
                </p>
            </div>

            <div className='pt-20 pr-20 pl-20  xl:pt-40  xl:pr-20 xl:pl-20  md:pt-40  md:pr-20 md:pl-20  sm:pt-30  sm:pr-2 sm:pl-2 xs:pr-5 xs:pl-5 2xs:pr-5 2xs:pl-5 relative overflow-hidden'>
                <div>
                    <img
                    src="/assets/img/quince.png"
                    alt="Coing"
                    className="absolute top-40 -left-10 w-24 opacity-5"
                    />   
                </div>
                <p className='text-center font-extrabold text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-nunito '> 
                    <Trans i18nKey="home.section2.title"></Trans>
                </p>
                <SVGSeparator width={120} height={50} className="" />
                <p className='pt-10 text-center font-nunito text-xl xl:pl-40 xl:pr-40'>
                    {t('home.section2.description')}
                </p>
            </div>

            <div className='pt-10  xl:pt-10 xl:pr-20 xl:pl-20  md:pt-10  md:pr-10 md:pl-10  sm:pt-10  sm:pr-10 sm:pl-10 xs:pr-0 xs:pl-0 '>
                <Product/>    
            </div>
            <div className='top-40 sm:top-40  md:top-40 xl:top-48 text-center py-10'>
                <Button
                    text={t('home.button.shop')}
                    onClick={() => navigate('/boutique')} // Redirection avec useNavigate
                    variant="black"
                />
            </div>

            {/* Section FAQ */}
            <div className='p-5 md:p-10'>
                <InstagramFeed />
            </div>
            <div>
                {/* Section Réseaux sociaux */}
                <SocialLinks />
            </div>
            </div>
        </>
    );
}
