import React, { useEffect, useState, useContext } from "react";
import EventCard from "../components/Cards/EventCard";
import { EventsContext } from "../services/EventsContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Importer les styles CSS de react-toastify

const GITHUB_TOKEN = "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"; // Remplacez par votre token GitHub
const REPO_OWNER = "SebastienGuimety"; // Remplacez par le propriétaire du dépôt
const REPO_NAME = "NewSiteCoing"; // Remplacez par le nom du dépôt
const EVENTS_FILE_PATH = "events.json"; // Chemin vers le fichier JSON pour les événements
const SALES_POINTS_FILE_PATH = "salesPoints.json"; // Chemin vers le fichier JSON pour les points de vente

// Fonction pour encoder en Base64 depuis UTF-8
function encodeBase64(string) {
    return btoa(
      encodeURIComponent(string).replace(/%([0-9A-F]{2})/g, (match, p1) =>
        String.fromCharCode(parseInt(p1, 16))
      )
    );
  }

const AdminPage = () => {
    const [loading, setLoading] = useState(false); // État global de chargement
    const [eventSubmitting, setEventSubmitting] = useState(false);
    const [salesPointSubmitting, setSalesPointSubmitting] = useState(false);
    const [deletingEventId, setDeletingEventId] = useState(null); // ID of the event being deleted
    const [deletingSalesPointId, setDeletingSalesPointId] = useState(null); // ID of the sales point being deleted
  const getCoordinates = async (address) => {
    const API_KEY = "AIzaSyCZKd9GsscBfVyRmXv1azAVbTlh0qPnfGc";
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${API_KEY}`
      );
      const data = await response.json();
      if (data.status === "OK") {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        console.error("Erreur lors de la récupération des coordonnées :", data.status);
        return null;
      }
    } catch (error) {
      console.error("Erreur de réseau :", error);
      return null;
    }
  };

  const fetchGitHubFile = async (filePath) => {
    try {
      const response = await fetch(
        `https://api.github.com/repos/${REPO_OWNER}/${REPO_NAME}/contents/${filePath}`,
        {
          headers: { Authorization: `Bearer ${GITHUB_TOKEN}` },
        }
      );
      const data = await response.json();
      return {
        content: JSON.parse(atob(data.content)),
        sha: data.sha,
      };
    } catch (error) {
      toast.error("Erreur lors de la récupération du fichier.");
      console.error(error);
      return { content: [], sha: null };
    }
  };

  const updateGitHubFile = async (filePath, newContent, sha) => {
    try {
      const response = await fetch(
        `https://api.github.com/repos/${REPO_OWNER}/${REPO_NAME}/contents/${filePath}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${GITHUB_TOKEN}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            message: `Mise à jour de ${filePath}`,
            content: encodeBase64(JSON.stringify(newContent, null, 2)),
            sha,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Erreur lors de la mise à jour du fichier.");
      }
      toast.success("Fichier mis à jour avec succès !");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour du fichier.");
      console.error(error);
    }
  };

  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("adminAuth") === "true"
  );
  const [password, setPassword] = useState("");

  const { events, setEvents, salesPoints, setSalesPoints } =
    useContext(EventsContext);

    const fetchAllData = async () => {
        const eventsData = await fetchGitHubFile(EVENTS_FILE_PATH);
        setEvents(eventsData.content);
    
        const salesPointsData = await fetchGitHubFile(SALES_POINTS_FILE_PATH);
        setSalesPoints(salesPointsData.content);
      };

      useEffect(() => {
        fetchAllData();
      }, []);
      const [newEvent, setNewEvent] = useState({
        title: "",
        startDate: "",
        endDate: "",
        time: "",
        location: "",
        description: "",
      });

  const [newSalesPoint, setNewSalesPoint] = useState({
    name: "",
    address: "",
    city: "",
  });

  const adminPassword = "mdp";

  const handleLogin = () => {
    if (password === adminPassword) {
      setAuthenticated(true);
      localStorage.setItem("adminAuth", "true");
    } else {
      alert("Mot de passe incorrect");
    }
  };

  const handleLogout = () => {
    setAuthenticated(false);
    localStorage.removeItem("adminAuth");
  };

  const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return Math.max(1, Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)));
  };

  const handleEventSubmit = async (e) => {
    e.preventDefault();
    if (!newEvent.title || !newEvent.startDate || !newEvent.location) {
      toast.error("Veuillez remplir tous les champs obligatoires pour l'événement.");
      return;
    }

    setEventSubmitting(true); // Start loading
    try {
      const updatedEvents = [
        ...events,
        {
          ...newEvent,
          id: events.length + 1,
          duration: newEvent.startDate && newEvent.endDate 
            ? calculateDuration(newEvent.startDate, newEvent.endDate)
            : 1, // Durée par défaut si aucune plage
        },
      ];

      const { sha } = await fetchGitHubFile(EVENTS_FILE_PATH);
      await updateGitHubFile(EVENTS_FILE_PATH, updatedEvents, sha);
      setEvents(updatedEvents);
      toast.success("Événement ajouté avec succès !");
      setNewEvent({
        title: "",
        startDate: "",
        endDate: "",
        time: "",
        location: "",
        description: "",
      });
    } catch (error) {
      toast.error("Erreur lors de l'ajout de l'événement.");
    } finally {
      setEventSubmitting(false); // End loading
    }
  };


  const handleSalesPointSubmit = async (e) => {
    e.preventDefault();
    if (!newSalesPoint.name || !newSalesPoint.address || !newSalesPoint.city) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    setSalesPointSubmitting(true); // Start loading
    try {
      const coordinates = await getCoordinates(
        `${newSalesPoint.address}, ${newSalesPoint.city}`
      );
      if (!coordinates) {
        toast.error("Impossible de récupérer les coordonnées.");
        return;
      }

      newSalesPoint.lat = coordinates.lat;
      newSalesPoint.lng = coordinates.lng;

      const updatedSalesPoints = [
        ...salesPoints,
        { ...newSalesPoint, id: salesPoints.length + 1 },
      ];
      const { sha } = await fetchGitHubFile(SALES_POINTS_FILE_PATH);
      await updateGitHubFile(SALES_POINTS_FILE_PATH, updatedSalesPoints, sha);
      setSalesPoints(updatedSalesPoints);
      toast.success("Point de vente ajouté avec succès !");
      setNewSalesPoint({
        name: "",
        address: "",
        city: "",
      });
    } catch (error) {
      toast.error("Erreur lors de l'ajout du point de vente.");
    } finally {
      setSalesPointSubmitting(false); // End loading
    }
  };

  const handleDeleteEvent = async (id) => {
    setDeletingEventId(id); // Définir l'ID de l'événement en cours de suppression
    try {
      const updatedEvents = events.filter((event) => event.id !== id);
      const { sha } = await fetchGitHubFile(EVENTS_FILE_PATH);
      await updateGitHubFile(EVENTS_FILE_PATH, updatedEvents, sha);
      setEvents(updatedEvents);
      toast.success("Événement supprimé avec succès !");
    } catch (error) {
      toast.error("Erreur lors de la suppression de l'événement.");
    } finally {
      setDeletingEventId(null); // Réinitialiser l'état après la suppression
    }
  };

  const handleDeleteSalesPoint = async (id) => {
    setDeletingSalesPointId(id); // Définir l'ID du point de vente en cours de suppression
    try {
      const updatedSalesPoints = salesPoints.filter((point) => point.id !== id);
      const { sha } = await fetchGitHubFile(SALES_POINTS_FILE_PATH);
      await updateGitHubFile(SALES_POINTS_FILE_PATH, updatedSalesPoints, sha);
      setSalesPoints(updatedSalesPoints);
      toast.success("Point de vente supprimé avec succès !");
    } catch (error) {
      toast.error("Erreur lors de la suppression du point de vente.");
    } finally {
      setDeletingSalesPointId(null); // Réinitialiser l'état après la suppression
    }
  };


  const [eventSearch, setEventSearch] = useState("");
    const [salesPointSearch, setSalesPointSearch] = useState("");

    // Filtrer les événements
    const filteredEvents = events.filter((event) =>
    event.title.toLowerCase().includes(eventSearch.toLowerCase())
    );

    // Filtrer les points de vente
    const filteredSalesPoints = salesPoints.filter(
    (point) =>
        point.name.toLowerCase().includes(salesPointSearch.toLowerCase()) ||
        point.city.toLowerCase().includes(salesPointSearch.toLowerCase())
    );

  if (!authenticated) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="p-6 bg-white shadow-lg rounded-lg">
          <h1 className="text-2xl font-bold mb-4">Admin Login</h1>
          <input
            type="password"
            placeholder="Mot de passe admin"
            className="border p-2 w-full mb-4"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            onClick={handleLogin}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
          >
            Connexion
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 bg-gray-50 min-h-screen mt-20 md:mt-60">
        <ToastContainer position="top-right" autoClose={5000} />

      <div className="flex justify-between items-center mb-8">
        <h1 className="text-4xl font-bold">Admin Panel</h1>
        {loading && <p className="text-blue-500">Chargement...</p>}

        <button
          onClick={handleLogout}
          className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700"
        >
          Déconnexion
        </button>
      </div>

      {/* Formulaire d'ajout d'événements */}
      <div className="bg-white p-6 shadow-md rounded-lg mb-8">
        <h2 className="text-2xl font-bold mb-4">Ajouter un événement</h2>
        <form onSubmit={handleEventSubmit} className="space-y-4">
          <input
            type="text"
            placeholder="Titre de l'événement *"
            className="border p-2 w-full"
            value={newEvent.title}
            onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
          />
          <div className="flex space-x-4">
            <input
              type="date"
              className="border p-2 w-1/2"
              value={newEvent.startDate}
              onChange={(e) =>
                setNewEvent({ ...newEvent, startDate: e.target.value })
              }
            />
            <input
              type="date"
              className="border p-2 w-1/2"
              value={newEvent.endDate}
              onChange={(e) =>
                setNewEvent({ ...newEvent, endDate: e.target.value })
              }
            />
          </div>
          <input
            type="time"
            className="border p-2 w-full"
            value={newEvent.time}
            onChange={(e) => setNewEvent({ ...newEvent, time: e.target.value })}
          />
          <input
            type="text"
            placeholder="Lieu *"
            className="border p-2 w-full"
            value={newEvent.location}
            onChange={(e) =>
              setNewEvent({ ...newEvent, location: e.target.value })
            }
          />
          <textarea
            placeholder="Description"
            className="border p-2 w-full"
            rows="3"
            value={newEvent.description}
            onChange={(e) =>
              setNewEvent({ ...newEvent, description: e.target.value })
            }
          />
          <button
            type="submit"
            disabled={eventSubmitting}
            className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 ${
              eventSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {eventSubmitting ? "Ajout en cours..." : "Ajouter"}
          </button>
        </form>
      </div>

      {/* Formulaire d'ajout de points de vente */}
      <div className="bg-white p-6 shadow-md rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Ajouter un point de vente</h2>
        <form onSubmit={handleSalesPointSubmit} className="space-y-4">
          <input
            type="text"
            placeholder="Nom *"
            className="border p-2 w-full"
            value={newSalesPoint.name}
            onChange={(e) => setNewSalesPoint({ ...newSalesPoint, name: e.target.value })}
          />
          <input
            type="text"
            placeholder="Adresse *"
            className="border p-2 w-full"
            value={newSalesPoint.address}
            onChange={(e) => setNewSalesPoint({ ...newSalesPoint, address: e.target.value })}
          />
          <input
            type="text"
            placeholder="Ville *"
            className="border p-2 w-full"
            value={newSalesPoint.city}
            onChange={(e) => setNewSalesPoint({ ...newSalesPoint, city: e.target.value })}
          />
          <button
            type="submit"
            disabled={salesPointSubmitting}
            className={`bg-green-500 text-white py-2 px-4 rounded hover:bg-green-700 ${
                salesPointSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
            >
            {salesPointSubmitting ? "Ajout en cours..." : "Ajouter"}
            </button>
        </form>
      </div>

      {/* Liste des événements */}
        {/* Liste des événements */}
      <div className="mb-8 pt-10">
        <h2 className="text-2xl font-bold mb-4">Événements ajoutés</h2>
        <ul className="space-y-4 max-h-[300px] overflow-y-auto border rounded">
          {filteredEvents.map((event) => (
            <li key={event.id} className="p-4 border rounded shadow bg-white relative">
              <h3 className="font-bold">{event.title}</h3>
              <p>
                Dates :{" "}
                {event.startDate}{" "}
                {event.endDate ? ` - ${event.endDate}` : ""}
              </p>
              <p>
                Durée :{" "}
                {event.duration
                  ? `${event.duration} jour${event.duration > 1 ? "s" : ""}`
                  : "N/A"}
              </p>
              <p>Heure : {event.time}</p>
              <p>Lieu : {event.location}</p>
              <p>Description : {event.description}</p>
              <button
                onClick={() => handleDeleteEvent(event.id)}
                disabled={deletingEventId === event.id}
                className={`absolute top-2 right-2 bg-red-500 text-white py-1 px-3 rounded hover:bg-red-700 ${
                  deletingEventId === event.id
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                {deletingEventId === event.id ? "Suppression..." : "Supprimer"}
              </button>
            </li>
          ))}
        </ul>
      </div>

        {/* Liste des points de vente */}
        <div>
        <h2 className="text-2xl font-bold mb-4 pt-8">Points de vente ajoutés</h2>
        <input
            type="text"
            placeholder="Rechercher un point de vente..."
            className="border p-2 w-full mb-4"
            value={salesPointSearch}
            onChange={(e) => setSalesPointSearch(e.target.value)}
        />
        <ul className="space-y-4 max-h-[300px] overflow-y-auto border rounded">
            {filteredSalesPoints.map((point) => (
            <li key={point.id} className="p-4 border rounded shadow bg-white relative">
                <h3 className="font-bold">{point.name}</h3>
                <p>{point.address}</p>
                <p>{point.city}</p>
                <p>
                Lat: {point.lat || "N/A"}, Lng: {point.lng || "N/A"}
                </p>
                <button
                    onClick={() => handleDeleteSalesPoint(point.id)}
                    disabled={deletingSalesPointId === point.id} // Désactiver si en cours de suppression
                    className={`absolute top-2 right-2 bg-red-500 text-white py-1 px-3 rounded hover:bg-red-700 ${
                    deletingSalesPointId === point.id ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                    {deletingSalesPointId === point.id ? "Suppression..." : "Supprimer"}
                </button>
            </li>
            ))}
        </ul>
        </div>

    </div>
  );
};

export default AdminPage;
