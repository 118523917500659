import React, { createContext, useEffect, useState } from "react";

const GITHUB_TOKEN = "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"; // Remplacez par votre token GitHub
const REPO_OWNER = "SebastienGuimety";
const REPO_NAME = "NewSiteCoing";
const EVENTS_FILE_PATH = "events.json";
const SALES_POINTS_FILE_PATH = "salesPoints.json";

// Fonction pour décoder Base64 en UTF-8
function decodeBase64(base64) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(base64), (c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
        .join("")
    );
  }
  
export const EventsContext = createContext();

const EventsProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
  const [salesPoints, setSalesPoints] = useState([]);

  const fetchGitHubFile = async (filePath) => {
  try {
    const response = await fetch(
      `https://api.github.com/repos/${REPO_OWNER}/${REPO_NAME}/contents/${filePath}`,
      {
        headers: { Authorization: `Bearer ${GITHUB_TOKEN}` },
      }
    );
    const data = await response.json();
    return {
      content: JSON.parse(decodeBase64(data.content)), // Décodage UTF-8
      sha: data.sha,
    };
  } catch (error) {
    console.error("Erreur lors de la récupération du fichier :", error);
    return { content: [], sha: null };
  }
};


  const fetchAllData = async () => {
    const eventsData = await fetchGitHubFile(EVENTS_FILE_PATH);
    setEvents(eventsData.content);

    const salesPointsData = await fetchGitHubFile(SALES_POINTS_FILE_PATH);
    setSalesPoints(salesPointsData.content);
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <EventsContext.Provider value={{ events, setEvents, salesPoints, setSalesPoints }}>
      {children}
    </EventsContext.Provider>
  );
};

export default EventsProvider;
