import React from 'react';
import Button from '../components/Buttons/Button'; // Assurez-vous que le chemin d'importation est correct
import { useTranslation } from 'react-i18next';
import products_fr from '../data/products_fr';
import products_en from '../data/products_en';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import { Helmet } from "react-helmet-async";


export const Boutique = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate(); // Initialisation de useNavigate
  const products = i18n.language === 'fr' ? products_fr : products_en;

  return (
    <>
      <Helmet>
        <title>Boutique | Au Coing du Ventoux</title>
        <meta name="description" content="Commandez notre liqueur et eau-de-vie de coing artisanale. Vente en ligne avec expédition rapide." />
        <meta name="keywords" content="acheter alcool de coing, boutique artisanal, liqueur de coing en ligne" />
        <meta property="og:title" content="Boutique | Au Coing du Ventoux" />
        <meta property="og:description" content="Commandez notre liqueur et eau-de-vie de coing artisanale. Vente en ligne avec expédition rapide." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://aucoingduventoux.com/boutique" />
        <meta property="og:image" content="https://aucoingduventoux.com/images/boutique.jpg" />
        <link rel="canonical" href="https://aucoingduventoux.com/boutique" />
      </Helmet>
      {/* Section principale avec le fond */}
      <div className="relative mx-auto py-16 md:px-10 md:pt-72 2xs:pt-24 bg-gray-50 overflow-hidden">
        {/* Dessin de "coings" en arrière-plan */}
        <div>
          <img
            src="/assets/img/quince.png"
            alt="Coing"
            className="absolute top-20 -left-10 w-24 opacity-5"
          />
          <img
            src="/assets/img/quince.png"
            alt="Coing"
            className="absolute top-80 -right-10 w-32 opacity-5"
          />
          <img
            src="/assets/img/quince.png"
            alt="Coing"
            style={{ top: '500px', left: '-10px' }}
            className="absolute  w-24 opacity-5"
          />
          <img
            src="/assets/img/quince.png"
            alt="Coing"
            style={{ top: '800px', right: '-10px' }}
            className="absolute  w-32 opacity-5"
          />
        </div>



        {/* Contenu de la page */}
        <div className="relative">
          <div className="absolute left-1/2 transform -translate-x-1/2 w-4/5 text-white">
            <p className="text-center text-black font-nunito font-bold text-2xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl">
              {t('boutique.title')}
            </p>
            <p className="relative text-black font-nunito text-center top-6 text-lg sm:text-xl md:text-1xl lg:text-xl xl:text-2xl">
              {t('home.description')}
            </p>
          </div>

          {/* Liste des produits */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-10 pt-36">
            {products.map((product) => (
              <div
                key={product.id}
                className="bg-white rounded-lg shadow-lg overflow-hidden"
              >
                {/* Image */}
                <img
                  src={product.imageSm}
                  alt={product.title}
                  className="w-full max-h-80 object-contain mx-auto"
                />

                {/* Détails du produit */}
                <div className="p-6">
                  <h2 className="font-bold text-lg">{product.title}</h2>
                  <p className="text-gray-600 mt-2 text-sm">
                    {product.description.slice(0, 1)}...
                  </p>
                  <p className="mt-4 font-semibold text-gray-900">
                    {product.price.toFixed(2)} €
                  </p>

                  <Button
                    text={t('boutique.product.discover')}
                    onClick={() => navigate(`/product/${product.id}`)}
                    variant="black"
                    className="mt-8 p-4"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
