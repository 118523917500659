import React from 'react';
import logoImage from '../assets/img/logoreal.png';  // Assurez-vous que le chemin vers votre logo est correct
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-zinc-800 text-white py-20 ">
      <div className="container mx-auto px-8 pt-10 pb-11 flex flex-col md:flex-row justify-between">
        {/* Logo Section */}
        <div className="flex justify-center items-center mb-8 md:mb-0">
          <img 
            src={logoImage} 
            alt="Distillerie de Paris" 
            className="w-32 h-32"
          />
        </div>

        {/* Company Information */}
        <div className="mb-8 md:mb-0 md:w-1/2 text-center justify-center">
          <p>SARL Soleu & Destre</p>
          <p>84340 Vaucluse, France</p>
          <p>contact@aucoingduventoux.com</p>
        </div>
        
        {/* Customer Service Links */}
      <div className="mb-8 md:mb-0 md:w-1/2 text-center justify-center">
        
        <ul>
          <li>
            <Link to="/politique-de-confidentialite" className="hover:underline">
            {t('footer.links.privacy_policy')}
            </Link>
          </li>
          <li>
            <Link to="/mentions-legales" className="hover:underline">
            {t('footer.links.legal_mentions')}
            </Link>
          </li>
        </ul>
      </div>
        
        
      </div>
      
      {/* Footer Bottom */}
      <div className=" mt-8 py-16 md:px-48 2xs:px-2 text-center">
        <p>{t('footer.message')}</p>
       
      </div>
      <div className="border-t border-gray-700 mt-8 pt-5 mb-2 text-center">
        <p className="mt-4 text-sm">{t('footer.copyright')}</p>
        <p className="text-sm">{t('footer.designed_by')}</p>
      </div>
    </footer>
  );
};

export default Footer;
