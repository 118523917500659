import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Button from '../components/Buttons/Button';

const AgeVerificationModal = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleAccept = () => {
    localStorage.setItem('ageVerified', 'true');
    setIsModalOpen(false);
    document.body.style.pointerEvents = 'all';
  };

  const handleDecline = () => {
    //alert(t('age_verification.alert'));
    window.location.href = 'https://www.google.com';
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.pointerEvents = 'none';
    }

    if (localStorage.getItem('ageVerified') === 'true') {
      setIsModalOpen(false);
      document.body.style.pointerEvents = 'all';
    }

    return () => {
      document.body.style.pointerEvents = 'all';
    };
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          maxWidth: '600px',
          maxHeight: '400px',
          margin: 'auto',
          padding: '40px',
          borderRadius: '15px',
          textAlign: 'center',
          inset: 'unset',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          pointerEvents: 'all',
        },
      }}
    >
      <h2 className="text-xl md:text-2xl font-bold mb-6">
        {t('age_verification.title')}
      </h2>
      <p className="text-sm md:text-lg text-gray-700 mb-8">
        {t('age_verification.description')}
      </p>
      <div>
        <Button
          text={t('age_verification.accept')}
          onClick={handleAccept}
          variant="blue"
          className="m-2"
        />
        <Button
          text={t('age_verification.decline')}
          onClick={handleDecline}
          variant="black"
        />
      </div>
    </Modal>
  );
};

export default AgeVerificationModal;
