import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeItem } from '../../redux/cartSlice';
import QuantitySelector from '../../components/Buttons/QuantitySelector';
import sendOrderEmail from '../../services/sendorder';
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';

const FullPanier = () => {
      const { t, i18n } = useTranslation();
  
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    object: '', 
  });

  const [orderNote, setOrderNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState({ type: '', message: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleQuantityChange = (item, newQuantity) => {
    const quantityDiff = newQuantity - item.quantity;
    if (quantityDiff > 0) {
      for (let i = 0; i < quantityDiff; i++) {
        dispatch(incrementQuantity(item.id));
      }
    } else if (quantityDiff < 0) {
      for (let i = 0; i < -quantityDiff; i++) {
        dispatch(decrementQuantity(item.id));
      }
    }
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  const validateForm = () => {
    const { name, email } = formData;
    if (!name || !email) {
      setFeedbackMessage({ type: 'error', message: t('cart.form.validation.missing') });
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFeedbackMessage({ type: 'error', message: t('cart.form.validation.invalid_email') });

      return false;
    }

    return true;
  };


  const handleSubmitOrder = async (e) => {
    e.preventDefault();

    if (cart.length === 0) {
      setFeedbackMessage({ type: 'error', message: t('cart.empty.warning') });

      return;
    }

    if (!validateForm()) return;

    setLoading(true);
    setFeedbackMessage({}); // Réinitialise les messages de feedback

    const total = calculateTotal();

    try {
      const result = await sendOrderEmail(cart, total, orderNote, formData, t);

      if (result.success) {
        setFeedbackMessage({ type: 'success', message: result.message });
        setOrderNote(''); // Réinitialise la note
        setFormData({ name: '', email: '', phone: '' }); // Réinitialise les champs
      } else {
        setFeedbackMessage({ type: 'error', message: result.message });
      }
    } catch (error) {
      setFeedbackMessage({ type: 'error', message: 'Une erreur s\'est produite lors de l\'envoi de la commande.' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Helmet>
        <title>Votre Panier | Au Coing du Ventoux</title>
        <meta name="description" content="Consultez votre panier et finalisez votre commande d'alcool de coing artisanal." />
        <meta property="og:title" content="Votre Panier | Au Coing du Ventoux" />
        <meta property="og:url" content="https://aucoingduventoux.com/fullpanier" />
        <link rel="canonical" href="https://aucoingduventoux.com/fullpanier" />
      </Helmet>
    <div className="container mx-auto py-8 mt-20 md:mt-48 px-4 sm:px-8">
      <h1 className="text-3xl font-bold text-center mb-8">{t('cart.title')}</h1>

      {cart.length === 0 ? (
        <p className=" pt-32 text-center text-gray-500 h-96">{t('cart.empty')}</p>
      ) : (
        <>
          <div className="hidden sm:block">
            <table className="w-full border-collapse">
              {/* Table header */}
              <thead>
                <tr>
                  <th className="border-b p-4 text-left">{t('cart.product')}</th>
                  <th className="border-b p-4 text-left">{t('cart.quantity')}</th>
                  <th className="border-b p-4 text-left">{t('cart.total')}</th>
                  <th className="border-b p-4 text-left"></th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody>
                {cart.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="p-8 flex items-center">
                      <img src={item.image} alt={item.title} className="w-20 h-32 object-contain mr-4" />
                      <div>
                        <h3 className="text-lg font-semibold">{item.title}</h3>
                        <p>{item.price} €</p>
                      </div>
                    </td>
                    <td className="p-4">
                      <div className="flex flex-col items-center">
                        
                        <div className="flex items-center">
                          <QuantitySelector
                            onQuantityChange={(newQuantity) => handleQuantityChange(item, newQuantity)}
                            showInput={true}
                            initialQuantity={item.quantity}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="p-4">{(item.price * item.quantity).toFixed(2)} €</td>
                    <td className="p-4 text-right">
                      <button
                        className="bg-black text-white py-3 px-6 rounded hover:bg-gray-700"
                        onClick={() => dispatch(removeItem(item.id))}
                      >
                        {t('cart.remove')}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Affichage sur les petits écrans */}
        <div className="block sm:hidden">
          {cart.map((item, index) => (
            <div key={index} className="border-b p-4 flex items-center">
              <img
                src={item.image}
                alt={item.title}
                className="w-16 h-24 object-contain mr-4"
              />
              <div className="flex-1">
                <h3 className="text-sm font-semibold">{item.title}</h3>
                <p className="mb-2 text-xs">{item.price} €</p>
                <div className="flex items-center">
                    <QuantitySelector
                      onQuantityChange={(newQuantity) => handleQuantityChange(item, newQuantity)}
                      showInput={true}
                      initialQuantity={item.quantity}
                    />
                  </div>
              </div>
              <button
                className="text-red-500 hover:underline ml-4 sm:hidden" // Icône visible uniquement sur petits écrans
                onClick={() => dispatch(removeItem(item.id))}
                aria-label="Supprimer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6" // Taille de l'icône
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>

          <div className="flex flex-wrap sm:flex-nowrap mt-8 gap-8">

            {/* Section droite : Informations importantes */}
            <div className="w-full sm:w-1/2">
              <h1 className="font-medium text-lg md:text-2xl mb-2 text-red-500">{t('cart.payment_info.title')}</h1>
              <ul className="list-disc list-inside">
                <li className='text-sm md:text-xl'>{t('cart.payment_info.note1')}</li>
                <li className='text-sm md:text-xl'>{t('cart.payment_info.note2')}</li>
                <li className='text-sm md:text-xl'>
                {t('cart.payment_info.note3')}
                </li>
              </ul>
              <p className="text-lg md:text-2xl font-bold mt-6">{t('cart.order.total')}: {calculateTotal()} €</p>
            </div>
            
            {/* Section gauche : Formulaire */}
            <div className="w-full sm:w-1/2">
              <form onSubmit={handleSubmitOrder}>
                <div className="mb-4">
                  <input
                    type="text"
                    name="name"
                    placeholder={t('cart.form.name')}
                    value={formData.name}
                    onChange={handleChange}
                    className="border p-2 w-full"
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="email"
                    name="email"
                    placeholder={t('cart.form.email')}
                    value={formData.email}
                    onChange={handleChange}
                    className="border p-2 w-full"
                    required
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="text"
                    name="phone"
                    placeholder={t('cart.form.phone')}
                    value={formData.phone}
                    onChange={handleChange}
                    className="border p-2 w-full"
                  />
                </div>
                {/* Nouveau champ : Professionnel ou Particulier */}
                <div className="mb-4">
                  
                  <select
                    name="object"
                    id="object"
                    placeholder={t('cart.form.object')}
                    value={formData.object}
                    onChange={handleChange}
                    className="border p-2 w-full"
                    required
                  >
                    <option value="" disabled>
                      -- Object --
                    </option>
                    <option value="particulier">{t('cart.form.object.particulier')}</option>
                    <option value="professionnel">{t('cart.form.object.professionnel')}</option>
                    <option value="autre">{t('cart.form.object.autre')}</option>
                  </select>
                </div>
                <label htmlFor="orderNote" className="block mb-2 mt-7">
                {t('cart.form.note')}
                </label>
                <textarea
                  id="orderNote"
                  value={orderNote}
                  onChange={(e) => setOrderNote(e.target.value)}
                  className="border p-2 w-full"
                  rows="4"
                ></textarea>
                {/* Messages de retour */}
                {feedbackMessage.message && (
                  <div
                    className={`mb-4 p-4 rounded ${
                      feedbackMessage.type === 'error' ? 'bg-red-100 text-red-600' : 'bg-green-100 text-green-600'
                    }`}
                  >
                    {feedbackMessage.message}
                  </div>
                )}
                <button
                  type="submit"
                  className="bg-black text-white py-3 px-6 rounded mt-4 hover:bg-gray-700"
                  disabled={loading}
                >
                  {loading ? t('cart.form.sending') : t('cart.form.submit')}
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
    </>
  );
};

export default FullPanier;


