import React from "react";
import { useContext } from "react";
import EventCard from "../components/Cards/EventCard";
import SVGSeparator from "../services/SVGSeparator";
import background from "../assets/img/ensemble_table_2.jpg"; // Assurez-vous que le chemin de l'image est correct
import { useTranslation } from "react-i18next";
import { EventsContext } from "../services/EventsContext";
import { Helmet } from "react-helmet-async";

const EventsPage = () => {
  const { t } = useTranslation();
  
  const { events } = useContext(EventsContext); // Accéder aux événements globaux

  return (
    <>
    <Helmet>
        <title>Événements | Au Coing du Ventoux</title>
        <meta name="description" content="Participez à nos événements autour de l'alcool de coing et découvrez nos dégustations et salons." />
        <meta name="keywords" content="dégustation alcool de coing, salon spiritueux, événements artisanaux, Mont Ventoux" />
        <meta property="og:title" content="Événements | Au Coing du Ventoux" />
        <meta property="og:description" content="Participez à nos événements autour de l'alcool de coing et découvrez nos dégustations et salons." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://aucoingduventoux.com/events" />
        <meta property="og:image" content="https://aucoingduventoux.com/images/events.jpg" />
        <link rel="canonical" href="https://aucoingduventoux.com/events" />
      </Helmet>
      {/* Section Héro */}
      <div
        className="relative bg-cover bg-center h-screen"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-6">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
            {t("events.bigtitle")}
          </h1>
          {/* Séparateur SVG */}
          <SVGSeparator width={120} height={50} className="" />
          <p className="mt-4 text-lg md:text-xl max-w-3xl">
            {t("events.bigdescription")}
          </p>
        </div>
      </div>

      {/* Liste des événements */}
      <div className="bg-gray-50 py-10">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-2">
          {t("events.title")}
        </h1>
        <p className="text-center px-6 py-5"> {t('sales.description')}</p>
        <SVGSeparator width={120} height={50} className="" />

        <div className="py-10 mx-auto px-10">
          {events.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {events.map((event) => (
                <EventCard key={event.id} {...event} />
              ))}
            </div>
          ) : (
            <div className="text-center py-10">
              <h2 className="text-2xl font-semibold text-gray-700">
                {t("events.no_upcoming")}
              </h2>
              <p className="text-gray-500 mt-2">{t("events.come_back_later")}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EventsPage;
