import React from "react";

const SVGSeparator = ({ width = 100, height = 50, className = "" }) => {
  return (
    <div className={`flex justify-center  ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 640 480"
        className="mx-auto"
      >
        <g className="layer">
          <title>Layer 1</title>
          <path
            d="M134.25,185.27c39.52,20.96 58.68,74.85 58.56,74.61c-0.12,-0.24 183.95,-135.09 204.31,-123.71c20.36,11.38 73.05,21.56 118.56,61.08c45.51,39.52 17.96,15.57 17.84,15.33"
            fill="none"
            stroke="#000000"
            strokeWidth="5"
          />
          <rect
            fill="none"
            height="9.95"
            stroke="#000000"
            strokeWidth="2"
            width="2.99"
            x="380.65"
            y="125.97"
          />
          <rect
            fill="none"
            height="0.9"
            stroke="#000000"
            strokeWidth="2"
            width="0.26"
            x="382.07"
            y="123.99"
          />
        </g>
      </svg>
    </div>
  );
};

export default SVGSeparator;
