import React from "react";
import { format, differenceInDays } from "date-fns"; // Installation : npm install date-fns
import { fr } from "date-fns/locale"; // Pour le français
import defaultImage1 from "../../assets/img/bouteillep.jpg";
import defaultImage2 from "../../assets/img/coudounat.jpeg";
import defaultImage3 from "../../assets/img/ensemble_table.jpg";
import defaultImage4 from "../../assets/img/petillant.jpeg";
import { useTranslation } from "react-i18next";

const EventCard = ({
  image,
  startDate,
  endDate,
  time,
  title,
  description,
  location,
}) => {
  const { t } = useTranslation();

  const defaultImage =
    "https://via.placeholder.com/400x300?text=Image+non+disponible";
  const defaultImages = [defaultImage1, defaultImage2, defaultImage3, defaultImage4];
  const randomDefaultImage = defaultImages[Math.floor(Math.random() * defaultImages.length)];

  // Formater les dates
  const formatDate = (date) => format(new Date(date), "d MMMM yyyy", { locale: fr });

  // Calculer la durée en jours
  const calculateDuration = (start, end) =>
    differenceInDays(new Date(end), new Date(start)) + 1;

  const formattedStartDate = startDate ? formatDate(startDate) : "Date inconnue";
  const formattedEndDate = endDate ? formatDate(endDate) : null;
  const duration =
    startDate && endDate ? calculateDuration(startDate, endDate) : null;

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col md:flex-row">
      {/* Image de l'événement */}
      <div className="md:w-1/3">
        <img
          src={image || randomDefaultImage} // Utiliser l'image fournie ou l'image par défaut
          alt={title}
          className="h-80 w-full md:h-full md:w-full object-cover"
        />
      </div>

      {/* Contenu de l'événement */}
      <div className="p-6 md:w-2/3">
        <h3 className="text-2xl font-bold text-gray-800">{title}</h3>
        <div className="flex flex-col text-gray-600 mt-2 space-y-2">
          <span>
            📅 {formattedStartDate}
            {formattedEndDate && ` - ${formattedEndDate}`}
          </span>
          {duration && (
            <span>⏳ {t("event.duration")}: {duration} {duration > 1 ? t("event.days") : t("event.day")}</span>
          )}
          {time && <span>🕒 {t("event.time")}: {time}</span>}
          <span>📍 {location || t("event.location_unknown")}</span>
        </div>
        <p className="text-gray-700 mt-4">{description}</p>
        <button className="mt-4 bg-black text-white py-2 px-4 rounded hover:bg-gray-700">
          {t("event.learn_more")}
        </button>
      </div>
    </div>
  );
};

export default EventCard;
