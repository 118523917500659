import emailjs from 'emailjs-com';

const sendOrderEmail = async (cart, total, orderNote, formData, t) => {
  try {
    const productDetails = cart
      .map(
        (item) =>
          `${item.quantity}x ${item.title} - ${item.price.toFixed(2)} € (Total : ${(item.price * item.quantity).toFixed(2)} €)`
      )
      .join('\n');

      console.log(productDetails);
      console.log(total);
      console.log("DUEUHZDZDOUOUZDUOZOUGOGUZDGUD");
      
    const result = await emailjs.send(
      'service_8cfov47', // Remplacez par votre Service ID
      'template_f6z31k8', // Remplacez par votre Template ID
      {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        order: formData.order,
        product_list: productDetails,
        total: `${total} €`,
        order_note: orderNote || 'Aucune note ajoutée.',
        object: formData.object,
      },
      'NkXzFJGQ3E8SLK7K8' // Remplacez par votre clé publique
    );

    if (result.text === 'OK') {
      return { success: true, message: t('cart.form.success') };
    } else {
      return { success: false, message: t('cart.form.error') };
    }
  } catch (error) {
    console.error('Erreur lors de l\'envoi de la commande :', error);
    return { success: false, message: t('cart.form.error') };
  }
};

export default sendOrderEmail;
