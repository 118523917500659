import React from "react";
import { Helmet } from "react-helmet-async";

const PolitiqueConfidentialite = () => {
  return (
    <>
    <Helmet>
        <title>Politique de Confidentialité | Au Coing du Ventoux</title>
        <meta name="description" content="Découvrez notre politique de confidentialité et la manière dont nous protégeons vos données personnelles." />
        <meta property="og:title" content="Politique de Confidentialité | Au Coing du Ventoux" />
        <meta property="og:url" content="https://aucoingduventoux.com/politique-de-confidentialite" />
        <link rel="canonical" href="https://aucoingduventoux.com/politique-de-confidentialite" />
      </Helmet>
    <div className="relative mx-auto py-16 md:px-10 md:pt-72 2xs:pt-24 bg-gray-50 overflow-hidden">
    <div className="bg-gray-50 min-h-screen p-8">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-4">Politique de Confidentialité</h1>
        <p className="mb-4">
          Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos informations personnelles lorsque vous utilisez ce site.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">1. Données collectées</h2>
        <p className="mb-4">
          Nous collectons uniquement les données nécessaires au bon fonctionnement du site, notamment :
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Les cookies strictement nécessaires pour garantir le bon fonctionnement du site</li>
          <li>Aucune donnée personnelle (nom, email, etc.) n'est collectée directement par ce site</li>
        </ul>

        <h2 className="text-2xl font-bold mt-6 mb-2">2. Finalité de la collecte</h2>
        <p className="mb-4">
          Les données collectées via les cookies nécessaires sont utilisées pour :
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Assurer le bon affichage et le fonctionnement des pages</li>
          <li>Garantir la sécurité de votre navigation</li>
          <li>Détecter et résoudre les éventuelles erreurs techniques</li>
        </ul>

        <h2 className="text-2xl font-bold mt-6 mb-2">3. Cookies</h2>
        <p className="mb-4">
          Les cookies utilisés sur ce site sont strictement limités à son fonctionnement de base. Ils ne collectent aucune information permettant de vous identifier ou de suivre votre navigation à des fins marketing.
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Cookies fonctionnels : </strong> Ces cookies permettent d'assurer le bon affichage et le fonctionnement des pages.
          </li>
          <li>
            <strong>Durée de conservation : </strong> Les cookies sont automatiquement supprimés dès la fermeture de votre navigateur ou après une période définie en fonction des besoins techniques.
          </li>
        </ul>
        <p className="mb-4">
          Conformément à la législation, aucun consentement explicite n'est nécessaire pour ces cookies strictement nécessaires.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">4. Sécurité des données</h2>
        <p className="mb-4">
          Nous mettons tout en œuvre pour protéger les informations et garantir la sécurité des données collectées sur ce site. Les cookies utilisés ne contiennent aucune donnée personnelle ou sensible.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">5. Vos droits</h2>
        <p className="mb-4">
          Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez des droits suivants :
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Droit d'accès et de consultation des informations collectées</li>
          <li>Droit de limiter ou de bloquer les cookies via les paramètres de votre navigateur</li>
        </ul>
        <p className="mb-4">
          Pour toute question relative à vos droits, vous pouvez nous contacter à : <strong>contact@aucoingduventoux.com</strong>.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">6. Modifications de la politique</h2>
        <p className="mb-4">
          Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment afin de nous conformer à la législation en vigueur ou pour refléter des changements dans nos pratiques. Toute modification sera publiée sur cette page avec une date de mise à jour.
        </p>
        <p className="mb-4">
          Dernière mise à jour : <strong>25/01/2025</strong>.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">7. Contact</h2>
        <p className="mb-4">
          Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, veuillez nous contacter à : <strong>contact@aucoingduventoux.com</strong>.
        </p>
      </div>
    </div>
    </div>
    </>
  );
};

export default PolitiqueConfidentialite;
