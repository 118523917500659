import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import imageright from '../assets/img/coudounat.jpeg';
import Button from '../components/Buttons/Button';
import handleSendEmail from '../services/email';
import { Helmet } from "react-helmet-async";

const ContactPage = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState({ type: '', message: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { name, email, message } = formData;
    if (!name || !email || !message) {
      setFeedbackMessage({
        type: 'error',
        message: t('contact.form.validation.missing'),
      });
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFeedbackMessage({
        type: 'error',
        message: t('contact.form.validation.invalid_email'),
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);
    setFeedbackMessage({});

    try {
      await handleSendEmail(formData);
      setFeedbackMessage({ type: 'success', message: t('contact.form.success') });
      setFormData({ name: '', email: '', phone: '', message: '' });
    } catch (error) {
      setFeedbackMessage({ type: 'error', message: t('contact.form.error') });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('contact.meta.title')}</title>
        <meta name="description" content={t('contact.meta.description')} />
        <meta property="og:title" content={t('contact.meta.og.title')} />
        <meta property="og:description" content={t('contact.meta.og.description')} />
        <meta property="og:url" content="https://aucoingduventoux.com/contact" />
        <link rel="canonical" href="https://aucoingduventoux.com/contact" />
      </Helmet>

      <div className="relative mx-auto py-16 md:px-10 md:pt-72 2xs:pt-24 overflow-hidden">
        <div className="flex flex-wrap">
          {/* Image à droite */}
          <div className="w-full md:w-1/2 p-8">
            <img src={imageright} alt="Boutique et Bouteilles" className="w-full h-full object-cover" />
          </div>

          {/* Formulaire de contact */}
          <div className="w-full md:w-1/2 p-8">
            <h1 className="text-5xl font-bold mb-6">{t('contact.titleoui')}</h1>
            <p className="mb-6">{t('contact.description1')}</p>
            <p className="mb-10">{t('contact.description2')}</p>

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  type="text"
                  name="name"
                  placeholder={t('contact.form.name')}
                  value={formData.name}
                  onChange={handleChange}
                  className="border p-2 w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  placeholder={t('contact.form.email')}
                  value={formData.email}
                  onChange={handleChange}
                  className="border p-2 w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="phone"
                  placeholder={t('contact.form.phone')}
                  value={formData.phone}
                  onChange={handleChange}
                  className="border p-2 w-full"
                />
              </div>
              <div className="mb-4">
                <textarea
                  name="message"
                  placeholder={t('contact.form.message')}
                  value={formData.message}
                  onChange={handleChange}
                  className="border p-2 w-full h-32"
                  required
                ></textarea>
              </div>
              {/* Messages de retour */}
              {feedbackMessage.message && (
                <div className={`mb-4 p-4 rounded ${feedbackMessage.type === 'error' ? 'bg-red-100 text-red-600' : 'bg-green-100 text-green-600'}`}>
                  {feedbackMessage.message}
                </div>
              )}

              {/* Bouton avec indicateur de chargement */}
              <Button
                text={loading ? t('contact.form.sending') : t('contact.form.submit')}
                type="submit"
                variant="black"
                disabled={loading}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
