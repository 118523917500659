import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import SVGSeparator from '../services/SVGSeparator';
import backgroundImage from '../assets/img/verrecoudounat.jpg';
import bouteillep from "../assets/img/bouteillep.jpg";
import background from "../assets/img/ensemble_table.jpg";
import img_innovation from "../assets/img/coudounat.jpeg";
import img_plasir from "../assets/img/ensemble_table.jpg";
import { Helmet } from "react-helmet-async";


const AboutUs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialisation de useNavigate

  return (
    <>
    <Helmet>
        <title>À Propos | Au Coing du Ventoux</title>
        <meta name="description" content="Découvrez notre histoire et notre engagement dans la production artisanale d'alcool de coing." />
        <meta property="og:title" content="À Propos | Au Coing du Ventoux" />
        <meta property="og:url" content="https://aucoingduventoux.com/aboutus" />
        <link rel="canonical" href="https://aucoingduventoux.com/aboutus" />
      </Helmet>
      {/* Section Héro */}
    <div className="relative bg-cover bg-center h-screen" style={{ backgroundImage: `url(${background})`}}>
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-6">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">{t('aboutus.inspiration')}</h1>
          {/* Séparateur SVG */}
            <SVGSeparator width={120} height={50} className="" />
          <p className="mt-4 text-lg md:text-xl max-w-3xl">
            {t('aboutus.inspiration_description')}
          </p>
        </div>
      </div>

      

      {/* Section Histoire et Mission */}
      <div className="bg-white py-16 px-6 md:px-20">

      <h2 className="text-center  px-6 text-3xl md:text-4xl font-bold text-gray-800">
          {t('about.history.title')}
        </h2>
        <SVGSeparator width={120} height={50} className="pb-16" />
      {/* Première section - Texte à gauche, image à droite */}
      <div className="flex flex-col lg:flex-row items-center lg:items-start gap-8 mb-16">
        {/* Texte */}
        <div className="lg:w-1/2 text-left">
          <h3 className="text-sm font-semibold text-amber-700 uppercase tracking-wide">
          {t('about.history.subtitle')}
          </h3>
          <h2 className="mt-4 text-3xl font-bold text-gray-800 leading-snug">
          {t('about.history.description')}
          </h2>
          <p className="mt-6 text-lg text-gray-600">{t('about.history.description1')}</p>
          <p className="mt-6 text-lg text-gray-600">{t('about.history.description2')}</p>
          <p className=" text-lg text-gray-600">{t('about.history.description3')}</p>
          <p className=" text-lg text-gray-600">{t('about.history.description4')}</p>
        </div>
        {/* Image */}
        <div className="lg:w-1/2">
          <img
            src={backgroundImage}
            alt="Coing"
            className="rounded-lg shadow-lg w-full"
          />
        </div>
      </div>
      

      {/* Deuxième section - Image à gauche, texte à droite */}
      <div className="flex flex-col lg:flex-row-reverse items-center lg:items-start gap-8">

        {/* Texte */}
        <div className="lg:w-1/2 text-left">
          <h3 className="text-sm font-semibold text-amber-700 uppercase tracking-wide">
          {t('about.history.production')}

          </h3>
          <h2 className="mt-4 text-3xl font-bold text-gray-800 leading-snug">
          {t('about.history.production_title')}
          </h2>
          <p className="mt-6 text-lg text-gray-600">
          {t('about.history.production_description1')}

          </p>
          <p className="mt-6 text-lg text-gray-600">{t('about.history.production_description2')}</p>
          <p className=" text-lg text-gray-600">{t('about.history.production_description3')}</p>
          <p className="mt-6 text-lg text-gray-600">{t('about.history.production_description4')}</p>
        </div>
        {/* Image */}
        <div className="lg:w-1/2">
          <img
            src={bouteillep}
            alt="Alambics"
            className="rounded-lg  w-full"
          />
        </div>
      </div>

      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-6">  
        {/* Section Histoire et Mission */}
        <div className="bg-white py-16 px-6 md:px-20">
        <h2 className="text-center  px-6 text-3xl md:text-4xl font-bold text-gray-800">
            {t('about.today.title')}
          </h2>
          <SVGSeparator width={120} height={50} className="pb-16" />
        </div>
      </div>
    
    {/* Deuxième section - Image à gauche, texte à droite */}
    <div className="flex flex-col lg:flex-row-reverse items-center lg:items-start gap-8 mb-16">
      {/* Texte */}
      <div className="lg:w-1/2 text-left">
        <h3 className="text-sm font-semibold text-amber-700 uppercase tracking-wide">
        {t('about.today.title_orange')}
        </h3>
        <h2 className="mt-4 text-3xl font-bold text-gray-800 leading-snug">
        {t('about.today.title_part_1')}
        </h2>
        <p className="mt-6 text-lg text-gray-600">
        {t('about.today.description1')}
        </p>
        <p className="mt-6 text-lg text-gray-600">{t('about.today.description2')}</p>
       
      </div>
      {/* Image */}
      <div className="lg:w-1/2">
        <img
          src={img_plasir}
          alt="Coing"
          className="rounded-lg  w-full"
        />
      </div>
    </div>
    
{/* Première section - Texte à gauche, image à droite */}
<div className="flex flex-col lg:flex-row items-center lg:items-start gap-8 ">
  {/* Texte */}
  <div className="lg:w-1/2 text-left">
    <h3 className="text-sm font-semibold text-amber-700 uppercase tracking-wide">
    {t('about.innovation.title')}
      
    </h3>
    <h2 className="mt-4 text-3xl font-bold text-gray-800 leading-snug">
    {t('about.today.title_part_2')}
    </h2>
    <p className="mt-6 text-lg text-gray-600">{t('about.today.innovation_description1')}</p>
    <p className="mt-6 text-lg text-gray-600">{t('about.today.innovation_description2')}</p>

    <p className=" text-lg text-gray-600">{t('about.today.innovation_description3')}</p>

    <p className="mt-6 text-lg text-gray-600">{t('about.today.innovation_description4')}</p>

    <p className="mt-6 text-lg text-gray-600">{t('about.today.innovation_description5')}</p>
    <p className="mt-6 text-lg text-gray-600">{t('about.today.innovation_description6')}</p>

    <p className="mt-6 text-lg text-gray-600">{t('about.today.innovation_description7')}</p>

  </div>
  {/* Image */}
  <div className="lg:w-1/2">
    <img
      src={img_innovation}
      alt="Coings"
      className="rounded-lg shadow-lg w-full"
    />
  </div>
</div>


    </div>
    

    </>
  );
};

export default AboutUs;
