import NavBar from "./components/Nav/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Boutique } from "./pages/Boutique";
import  ProductDetail  from "./pages/products/ProductDetail";
import ScrollToTop from './components/Nav/ScrollToTop';  // Assurez-vous que le chemin est correct
import { Provider } from 'react-redux';
import { store } from './redux/store';
import MentionsLegales from "./pages/MentionsLegales";
import FullPanier from "./pages/Panier/FullPanier";
import Footer from "./pages/Footer";
import ContactPage from "./pages/Contact";
import AboutUs from "./pages/About";
import SalesPointsMap from "./pages/SalesPointsMap";
import EventsPage from "./pages/EventsPage";
import AdminPage from "./pages/AdminPage";
import EventsProvider from "./services/EventsContext";
import PolitiqueConfidentialite from "./pages/PolitiqueConfidentialite";
import FAQ from "./components/FAQ/FAQ";
import NotFound from "./pages/NotFound";
import AgeVerificationModal from "./pages/AgeVerificationModal";
import React, { useState } from 'react';
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <>
    <HelmetProvider>
      <EventsProvider>
        <BrowserRouter>
          <Provider store={store}>
            <NavBar />
            <ScrollToTop />
            
            {localStorage.getItem('ageVerified') !== 'true' && (
              <AgeVerificationModal />
            )}
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/sales" element={<SalesPointsMap/>}/>
              <Route path="/boutique" element={<Boutique/>}/>
              <Route path="/fullpanier" element={<FullPanier/>}/>
              <Route path="/contact" element={<ContactPage/>}/>
              <Route path="/product/:id" element={<ProductDetail />} />
              <Route path="/aboutus" element={<AboutUs/>}/>
              <Route path="/events" element={<EventsPage/>}/>
              <Route path="/administration" element={<AdminPage/>}/>
              <Route path="/mentions-legales" element={<MentionsLegales/>}/>
              <Route path="/politique-de-confidentialite" element={<PolitiqueConfidentialite/>}/>
              <Route path="*" element={<NotFound />} /> {/* Use NotFound component */}
            </Routes>
            <FAQ />
            <Footer />
          </Provider>
        </BrowserRouter>
      </EventsProvider>
    </HelmetProvider>
    </>
  );
}

export default App;
