import React from "react";
import { Helmet } from "react-helmet-async";

const MentionsLegales = () => {
  return (
    <>
    <Helmet>
        <title>Mentions Légales | Au Coing du Ventoux</title>
        <meta name="description" content="Consultez nos mentions légales pour en savoir plus sur notre entreprise et nos conditions d'utilisation." />
        <meta property="og:title" content="Mentions Légales | Au Coing du Ventoux" />
        <meta property="og:url" content="https://aucoingduventoux.com/mentions-legales" />
        <link rel="canonical" href="https://aucoingduventoux.com/mentions-legales" />
      </Helmet>
    <div className="relative mx-auto py-16 md:px-10 md:pt-72 2xs:pt-24 bg-gray-50 overflow-hidden">
    <div className="bg-gray-50 min-h-screen p-8">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-4">Mentions Légales</h1>
        <p className="mb-4">
          Conformément à la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique (LCEN), nous vous informons des éléments suivants concernant ce site :
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">Propriétaire du site</h2>
        <p className="mb-4">
          <strong>Nom de l’entreprise : </strong>Soleu E Destre <br />
          <strong>Forme juridique : </strong>SARL <br />
          <strong>Adresse du siège social : </strong>Soleu E Destre 84340, Beaumont-Du-Ventoux <br />
          <strong>Numéro SIRET : </strong>[Votre numéro SIRET] <br />
          <strong>Numéro RCS : </strong>[Votre numéro RCS] <br />
          <strong>Numéro de TVA intracommunautaire : </strong>[Votre numéro TVA]
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">Responsable de la publication</h2>
        <p className="mb-4">
          <strong>Nom et prénom : </strong>Guimety Sebastien <br />
          <strong>Contact : </strong>contact@aucoingduventoux.com
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">Hébergeur</h2>
        <p className="mb-4">
          <strong>Nom : </strong>LWS <br />
          <strong>Adresse : </strong>10 rue Penthievre – 75008 - Paris France <br />
          <strong>Téléphone : </strong>06 25 50 11 55
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">Avertissement</h2>
        <p className="mb-4">
          L'abus d'alcool est dangereux pour la santé. À consommer avec modération.
          Ce site est réservé aux personnes majeures.
        </p>

        <h2 className="text-2xl font-bold mt-6 mb-2">Contact</h2>
        <p className="mb-4">
          Pour toute question ou réclamation concernant ce site, veuillez nous contacter à l'adresse suivante : <strong>contact@aucoingduventoux.com</strong>.
        </p>
      </div>
      </div>
    </div>
    </>
  );
};

export default MentionsLegales;
